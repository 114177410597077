import React from 'react';
import { useRootState } from 'store/StateProvider';
import { AreaChart, XAxis, YAxis, CartesianGrid, Tooltip, Area, Legend, Label } from 'recharts';

import { useCurrentTableData } from 'hooks/useCurrentTableData';

import { TargetGraphHookType, GraphsTransformArgs } from 'types';
import './targetGraph.scss';
import RenderLegend from '../RenderLegend';

const dataType = 'dailyData';
const transformForTargetGraph: GraphsTransformArgs<TargetGraphHookType> = (
    el,
    index,
    results,
    isDell = false,
    firstYearMonths = 12,
) => ({
    month: (12 - firstYearMonths) * 30 + index + 1,
    [`${isDell ? '' : 'EWL '}Storage LTR(TB)`]:
        Math.round(results.ltr[dataType][index]?.targetTbDaily * 100) / 100 || 0,
    [`${isDell ? '' : 'EWL '} Storage Standard(TB)`]:
        Math.round(results.noLtr[dataType][index]?.targetTbDaily * 100) / 100 || 0,
    [`${isDell ? '' : 'EWL '} Storage Archive(TB)`]:
        Math.round(results.dtc[dataType][index]?.targetTbDaily * 100) / 100 || 0,
});

const LegendCompoent = (payload) => <RenderLegend payload={payload} />;

const TargetGraph = (): JSX.Element => {
    const {
        location: { isDell },
        sourcesData: { tcoYear, firstYearMonths },
    } = useRootState();

    const { currentSourceData } = useCurrentTableData<TargetGraphHookType>(
        tcoYear,
        0,
        dataType,
        transformForTargetGraph,
        undefined,
        undefined,
        isDell,
        firstYearMonths,
    );
    const graphSourceData = currentSourceData || [];

    const [lastMonthStorageObject] = graphSourceData && !!graphSourceData.length ? graphSourceData.slice(-1) : [{}];

    return (
        <AreaChart width={900} height={450} data={graphSourceData} margin={{ bottom: 100, right: 10 }}>
            <XAxis
                axisLine={false}
                tickLine={false}
                interval={90}
                dataKey="month"
                tickFormatter={(tick) => String(Math.trunc(((tick / 30) * 10) / 10) + 1)}
            >
                <Label value="Month" style={{ fill: '#000' }} offset={-30} position="insideBottomLeft" />
            </XAxis>
            <YAxis axisLine={false} tickLine={false} tickMargin={20}>
                <Label value="TB" style={{ fill: '#000' }} offset={15} position="bottom" />
            </YAxis>
            <CartesianGrid strokeDasharray="3 3" />
            <Legend
                verticalAlign="top"
                wrapperStyle={{ top: 0 }}
                height={50}
                content={({ payload }) => LegendCompoent(payload)}
            />
            <Tooltip />
            {lastMonthStorageObject[`${isDell ? '' : 'EWL '}Storage LTR(TB)`] && (
                <Area
                    type="monotone"
                    dataKey={`${isDell ? '' : 'EWL '}Storage LTR(TB)`}
                    stroke="rgb(255,153,0)"
                    fillOpacity={1}
                    fill="rgba(255,153,0, 0.4)"
                />
            )}
            {lastMonthStorageObject[`${isDell ? '' : 'EWL '} Storage Standard(TB)`] && (
                <Area
                    type="monotone"
                    dataKey={`${isDell ? '' : 'EWL '} Storage Standard(TB)`}
                    stroke="rgb(20,110,180)"
                    fillOpacity={1}
                    fill="rgba(20,110,180, 0.1)"
                />
            )}
            {lastMonthStorageObject[`${isDell ? '' : 'EWL '} Storage Archive(TB)`] && (
                <Area
                    type="monotone"
                    dataKey={`${isDell ? '' : 'EWL '} Storage Archive(TB)`}
                    stroke="rgb(105,205,10)"
                    fillOpacity={1}
                    fill="rgba(105, 205, 10, 0.2)"
                />
            )}
        </AreaChart>
    );
};

export default TargetGraph;
