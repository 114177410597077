import * as Yup from 'yup';

export const sourceValidation = Yup.object().shape({
    type: Yup.string().notOneOf(['select'], 'Choose one of values').required('Required'),
    size: Yup.number().moreThan(0, 'Data size must be greater than 0').required('Required'),
    annualGrowth: Yup.number().required('Required'),
    dailyGrowth: Yup.number().required('Required'),
    dailyChange: Yup.number().required('Required'),
    deploymentPeriod: Yup.number(),
    retention: Yup.object()
        .shape({
            daily: Yup.number().required('Required'),
            weekly: Yup.number().required('Required'),
            monthly: Yup.number().required('Required'),
            yearly: Yup.number().required('Required'),
        })
        .test(
            'a1WhenBIsZ',
            'Number of daily recovery points should not exceed the max LTR threshold of 60',
            function (value) {
                if (this.parent.ltr === 'ltr' && value.daily > 60) {
                    return false;
                }

                return true;
            },
        ),
    ltr: Yup.string().oneOf(['nA', 'warm', 'ltr']),
    dtc: Yup.string().required('Required'),
});
export const sourcesValidationSchema = Yup.object().shape({
    sources: Yup.array().of(sourceValidation).required('Must have a source'),
});
